import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {trackEventWithBrowserProps} from '../../../../logging/Analytics'
import {useDeleteRoleRequestMutation} from '../../../hooks/useRoleRequestMutation'

type DeactivateLinkDialogProps = {
  toBeDeactivatedLink: string | null
  setToBeDeactivatedLink: (userId: string | null) => void
  loggedInUserId: string
  roleRequestId?: string
}

export const DeactivateLinkDialog = ({
  loggedInUserId,
  roleRequestId,
  setToBeDeactivatedLink,
  toBeDeactivatedLink
}: DeactivateLinkDialogProps) => {
  const {t} = useTranslation()
  const deleteRoleRequestMutation = useDeleteRoleRequestMutation()

  const deactivateRoleRequest = () => {
    if (!roleRequestId) {
      return
    }
    void deleteRoleRequestMutation
      .mutateAsync(roleRequestId)
      .then(() => {
        trackEventWithBrowserProps('inviteDeactivateSuccess', {
          product: 'hub',
          date: new Date().toISOString(),
          roleRequestId,
          userId: loggedInUserId
        })
      })
      .catch((error) => {
        trackEventWithBrowserProps('inviteDeactivateFailed', {
          product: 'hub',
          date: new Date().toISOString(),
          userId: loggedInUserId,
          roleRequestId,
          detail: error.response.data.detail
        })
      })
  }

  return (
    <Dialog
      open={!!toBeDeactivatedLink}
      onClose={() => setToBeDeactivatedLink(null)}
      aria-labelledby="roleRequest-deactivation-confirmation-dialog"
      aria-describedby="roleRequest-deactivation-confirmation-dialog-description"
      data-test-id={`roleRequest-deactivation-confirmation-dialog-link-${toBeDeactivatedLink}`}
    >
      <DialogTitle id="roleRequest-deactivation-confirmation-dialog">
        {t('invitations.deactivationConfirmationTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="roleRequest-deactivation-confirmation-dialog-description"
          style={{color: '#00374d'}}
        >
          {t('invitations.deactivationConfirmationText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setToBeDeactivatedLink(null)}
          color="primary"
          variant="text"
          data-test-id={`roleRequest-deactivation-confirmation-dialog-link-${toBeDeactivatedLink}-cancel`}
        >
          {t('invitations.reject_deactivate')}
        </Button>
        <Button
          onClick={() => {
            toBeDeactivatedLink && deactivateRoleRequest()
            setToBeDeactivatedLink(null)
          }}
          variant="text"
          style={{color: '#CA605F'}}
          data-test-id={`roleRequest-deactivation-confirmation-dialog-link-${toBeDeactivatedLink}-confirm`}
        >
          {t('invitations.confirm_deactivate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
