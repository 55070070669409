import {Customer} from '@hconnect/apiclient/src/response/Customer'
import {DEFAULT_CUSTOMER} from '@hconnect/hub/src/App.constants'
import {useQuery} from 'react-query'

import {getRequestPathData} from './utils'

const fetchCustomerByNumber = ({queryKey}) => {
  const [endpoint, {customerNumber}] = queryKey

  const queryParams = new URLSearchParams({})

  if (customerNumber) {
    queryParams.append('customerNumber', customerNumber)
  }

  return getRequestPathData<Customer[]>(`/${endpoint}?${queryParams.toString()}`)
}

const fetchByCustomerSearchTerm = ({queryKey}) => {
  const [endpoint, {searchTerm}] = queryKey

  const queryParams = new URLSearchParams({})

  if (searchTerm) {
    queryParams.append('searchTerm', searchTerm)
  }

  return getRequestPathData<Customer[]>(`/${endpoint}/search?${queryParams.toString()}`)
}

const fetchCustomerById = ({queryKey}) => {
  const [endpoint, {customerId}] = queryKey

  return getRequestPathData<Customer>(`/${endpoint}/${customerId}`)
}

const fetchCustomers = ({queryKey}) => {
  const [endpoint, {businessLine, countryId, orgUnitId}] = queryKey

  if (businessLine && countryId && orgUnitId) {
    const queryParams = new URLSearchParams({})
    queryParams.append('countryId', countryId)
    queryParams.append('orgUnitId', orgUnitId)
    queryParams.append('businessLine', businessLine)

    return getRequestPathData<Customer[]>(`/${endpoint}?${queryParams.toString()}`)
  }

  return getRequestPathData<Customer[]>(`/${endpoint}`)
}

export const useCustomerByNumber = (customerNumber?: string, enabled?: boolean) => {
  return useQuery(['customers', {customerNumber}], fetchCustomerByNumber, {
    retry: false,
    enabled
  })
}

export const useCustomerByNumberLogic = (
  customerNumber?: string,
  enabled?: boolean,
  userId?: string
) => {
  const customerQueryInfo = useCustomerByNumber(customerNumber, enabled)
  const storedCustomer = localStorage.getItem(`${DEFAULT_CUSTOMER}-${userId}`)
  const parsedStoreCustomer = storedCustomer ? JSON.parse(storedCustomer) : null

  const customerFound = !!customerQueryInfo.data?.length
  const customerNotFound = customerQueryInfo.data?.length === 0

  const selectedCustomer = parsedStoreCustomer
    ? customerQueryInfo.data?.find(
        (customerData) =>
          customerData.customerName === parsedStoreCustomer.customerName &&
          customerData.customerNumber === parsedStoreCustomer.customerNumber
      ) || customerQueryInfo.data?.[0]
    : customerQueryInfo.data?.[0]

  return {
    isCustomerByNumberLoading: customerQueryInfo.isLoading,
    customerFound,
    customerNotFound,
    selectedCustomer,
    allCustomers: customerQueryInfo.data
  }
}

export const useCustomerBySearchTerm = (searchTerm?: string, enabled?: boolean) => {
  return useQuery(['customers', {searchTerm}], fetchByCustomerSearchTerm, {
    retry: false,
    enabled
  })
}

export const useCustomerBySearchTermLogic = (searchTerm?: string, enabled?: boolean) => {
  const customerSearchTermQueryInfo = useCustomerBySearchTerm(searchTerm, enabled)

  const customerSearchTermItems = customerSearchTermQueryInfo.data?.map((customer) => ({
    label: customer.customerName,
    value: customer.customerNumber ?? ''
  }))

  const customSearchTermFound =
    customerSearchTermQueryInfo.data && customerSearchTermQueryInfo.data?.length > 0
  const customSearchTermNotFound =
    customerSearchTermQueryInfo.data && customerSearchTermQueryInfo.data?.length === 0
  const selectedSearchTermCustomer =
    customerSearchTermQueryInfo.data?.find(
      (customer) =>
        searchTerm &&
        (customer.customerId === searchTerm ||
          customer.customerName.includes(searchTerm) ||
          customer.customerNumber === searchTerm)
    ) || customerSearchTermQueryInfo.data?.[0]

  return {
    customerSearchTermItems,
    customSearchTermFound,
    customSearchTermNotFound,
    selectedSearchTermCustomer,
    isCustomerBySearchTermLoading: customerSearchTermQueryInfo.isLoading,
    isCustomerBySearchTermError: customerSearchTermQueryInfo.isError
  }
}

export const useCustomerById = (customerId?: string) => {
  return useQuery(['customers', {customerId}], fetchCustomerById, {
    retry: false,
    enabled: !!customerId
  })
}

export const useCustomers = (
  enabled?: boolean,
  businessLine?: string,
  countryId?: string,
  orgUnitId?: string
) => {
  return useQuery(['customers', {businessLine, countryId, orgUnitId}], fetchCustomers, {
    enabled
  })
}

export const useCustomerLogic = (
  customerIdsFromRole: string[],
  hasCustomerOrPayerAssignments: boolean,
  salesAgentDataScopes?: {businessLine: string; countryId: string; orgUnitId: string},
  enabled?: boolean
) => {
  const customersQueryInfo = useCustomers(
    enabled,
    salesAgentDataScopes?.businessLine,
    salesAgentDataScopes?.countryId,
    salesAgentDataScopes?.orgUnitId
  )

  const filteredCustomers = !hasCustomerOrPayerAssignments
    ? customersQueryInfo.data
    : customersQueryInfo.data?.filter((customer) =>
        customerIdsFromRole.includes(customer.customerId)
      )

  const customerItems = filteredCustomers?.map((customer) => ({
    value: customer.customerNumber ?? 'no-customer-number',
    label: customer.customerName
  }))

  return {
    isCustomerLoading: customersQueryInfo.isLoading,
    isCustomerError: customersQueryInfo.isError,
    customerItems
  }
}
