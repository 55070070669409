import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {NumericTextField} from '../../../components/forms/NumericTextField'

type Props = {
  value?: number
  min?: number
  max?: number
  step?: number
  onChange: (value?: number) => void
  error: boolean
  'data-test-id': string
}

const useStyles = makeStyles()(() => ({
  incrementArrows: {
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      marginLeft: '-4px'
    }
  }
}))

export const NumberInput: React.FC<Props> = ({
  value,
  step = 1,
  min,
  max,
  onChange,
  error,
  'data-test-id': dataTestId
}) => {
  const {classes} = useStyles()
  return (
    <NumericTextField
      data-test-id={dataTestId}
      error={error}
      value={value}
      style={{maxWidth: '50px'}}
      InputProps={{
        inputProps: {
          min,
          step,
          max,
          style: {padding: '16px 4px', textAlign: 'center'},
          className: classes.incrementArrows
        }
      }}
      onChange={(event) => {
        if (event.target.value) {
          const newValue = Number(event.target.value)
          if (min && newValue < min) {
            onChange(min)
          }
          if (max && newValue > max) {
            onChange(max)
          }
          onChange(newValue)
        } else {
          onChange()
        }
      }}
    />
  )
}
