import {HoverContainer, Typography, useBreakPoints} from '@hconnect/uikit'
import {Box, Button, Snackbar, makeStyles} from '@material-ui/core'
import {Warning} from '@material-ui/icons'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import ReplyIcon from '@material-ui/icons/Reply'
import Alert from '@material-ui/lab/Alert'
import {AxiosError} from 'axios'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {UseMutationResult} from 'react-query'

import {useAuth} from '../../../authentication/context'
import shareInvitationLink from '../../assets/share_invitation_link.svg'
import {Role, RoleRequestResponseJSON} from '../../hooks/useRoleRequestMutation'
import {handleMobileShare} from '../../Overview/utils/handleMobileShare'

const useClipboard = () => (text: string) => navigator.clipboard.writeText(text)

type Props = {
  countryId: string
  roleRequestMutation: UseMutationResult<
    RoleRequestResponseJSON,
    unknown,
    {
      roles: Role[]
      usageLimit: number
      validDays: number
    }
  >
}

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: '16px',
    borderRadius: 4
  },
  notice: {
    fontSize: '12px'
  },
  link: {
    fontSize: '13px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: '8px'
  },
  boxBlue: {
    background: '#E8F5FD !important',
    cursor: 'pointer'
  },
  copyIcon: {
    cursor: 'pointer',
    color: theme.palette.info.main,
    transition: 'color .25s ease-in-out',
    '&:hover': {
      color: theme.palette.info.dark
    }
  },
  copyLinkButton: {
    flex: 1,
    textTransform: 'none',
    fontWeight: 600,
    background: '#016AD4',
    outline: 'none',
    borderRadius: '6px',
    border: '1px solid #016AD4',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    color: '#FFFFFF',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: '#016AD4',
      color: '#FFFFFF'
    }
  }
}))

export const RoleRequestLink: React.FC<Props> = ({roleRequestMutation, countryId}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const copyText = useClipboard()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const {decodedToken} = useAuth()
  const loggedInUserId = decodedToken?.user_id || ''

  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const roleRequestId = roleRequestMutation.data?.roleRequestId
  const isError = roleRequestMutation.isError

  const mutationError =
    isError &&
    (roleRequestMutation.error as AxiosError<{title: string; detail: string}>).response?.data

  const link = `${window.location.origin}/receiveInvite/${countryId}/${roleRequestId}`

  if (!roleRequestId) {
    return null
  }

  const text = isError && mutationError ? `${mutationError.title}: ${mutationError.detail} ` : link

  if (isError) {
    return (
      <Box mt={2}>
        <Typography
          variant="h4"
          component="div"
          data-test-id="invite-create-invitation-link"
          color="error"
        >
          {text}
        </Typography>
      </Box>
    )
  }

  const linkBox = (
    <Box m={1} style={{wordBreak: 'break-word', textAlign: 'justify'}}>
      <Typography color="textPrimary">{t('invite.create.invitationLinkCreated')}</Typography>
      <Typography color="textPrimary" className={classes.link}>
        {link}
      </Typography>
    </Box>
  )

  return (
    <>
      <Box mt={2}>
        <HoverContainer
          title={t('invite.create.copyLinkToClipboard')}
          onClick={() => {
            setIsSnackbarOpen(true)
            void copyText(link)
          }}
          className={classes.boxBlue}
        >
          <Typography
            variant="h4"
            component="div"
            data-test-id="invite-create-invitation-link"
            style={{borderLeft: 0}} // Kein Bock to remove borderLeft from packages/uikit/src/lib/Molecules/HoverContainer.tsx, why is it even there?
          >
            {linkBox}
          </Typography>
        </HoverContainer>
        <Box mt={2} mb={2}>
          {isMobile ? (
            <Button
              startIcon={
                <img
                  style={{marginTop: '-2px'}}
                  src={shareInvitationLink}
                  alt="share invitation link"
                />
              }
              data-test-id="share-link"
              className={classes.copyLinkButton}
              onClick={() =>
                handleMobileShare(setIsSnackbarOpen, loggedInUserId, link, t, roleRequestId)
              }
              fullWidth
            >
              {t('invitations.shareLink')}
            </Button>
          ) : (
            <Button
              startIcon={<FilterNoneIcon fontSize="small" />}
              data-test-id="generate-link"
              className={classes.copyLinkButton}
              onClick={() => {
                setIsSnackbarOpen(true)
                void copyText(link)
              }}
              fullWidth
            >
              {t('invitations.copyLink')}
            </Button>
          )}
        </Box>

        <Box mt={3}>
          <Box mb={1} display="flex" flexDirection="row" alignItems="flex-end">
            <Box display="flex" mr={1} alignSelf="center">
              <Warning color="error" />
            </Box>
            <Typography customVariant="formLabel" component="h3">
              {t('invite.create.publicLink')}
            </Typography>
          </Box>
          <Typography variant="body1" component="p" className={classes.notice}>
            {t('invite.create.notice')}
          </Typography>
        </Box>

        <Box className={classes.disclaimer} mt={3}>
          <Box mt={1} mb={2}>
            <Typography color="textPrimary" variant="subtitle2">
              {t('invite.create.disclaimerHeader')}
            </Typography>
          </Box>
          <Box>
            <Typography color="textPrimary" variant="caption">
              {t('invite.create.disclaimer')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert onClose={() => setIsSnackbarOpen(false)} severity="success">
          {t('invitations.copied')}!
        </Alert>
      </Snackbar>
    </>
  )
}
