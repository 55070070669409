import {RoleRequest as RoleRequestJSON} from '@hconnect/apiclient'
import {dateFormatter, Typography, useTranslation} from '@hconnect/uikit'
import {Box, Grid} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import {makeStyles} from '@material-ui/core/styles'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import classNames from 'classnames'
import React, {CSSProperties, useMemo, useState} from 'react'

import {useOrgUnitConfiguration} from '../../hooks/useConfigurations'
import {useCustomerById} from '../../hooks/useCustomers'
import {usePayerById} from '../../hooks/usePayers'
import {getRoleRequestStates} from '../../utils'

import {RoleRequestStateTag} from './RoleRequestStateTag'

const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14
const NEW_USER_BUBBLE_FONT_SIZE = 12
const SUMMARY_HEIGHT = 100
const ERROR_ORANGE = '#ffa500'

const useStyles = makeStyles({
  date: {
    fontSize: 18,
    fontWeight: 500,
    margin: 1
  },
  titleAbove: {
    color: 'grey',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
  },
  info: {
    color: 'grey'
  },
  title: {
    margin: 0,
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  accordionSummary: {
    height: SUMMARY_HEIGHT,
    '& .MuiAccordionSummary-expandIcon': {
      order: -1
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    }
  },
  borderLineProgess: {
    height: 5,
    borderRadius: 10,
    width: 150,
    backgroundColor: 'lightgrey',
    '& .MuiLinearProgress-barColorPrimary': {
      borderRadius: 10,
      backgroundColor: '#53C069',
      color: 'red'
    }
  },
  listElement: {
    borderBottom: '1px solid lightgrey',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  selectedListElement: {
    backgroundColor: 'rgba(1, 106, 212, 0.08)'
  }
})

export const checkIfUsedByIsNewUser = (usedByInfo: RoleRequestJSON['usedBy'][0]) => {
  const newUserUntil = new Date(new Date(usedByInfo.usedOn).getTime() + TWO_WEEKS)

  return newUserUntil > new Date()
}

export type RoleRequestProps = {
  roleRequest: RoleRequestJSON
  setSelectRoleRequestId: (id: string) => void
  isRowSelected: boolean
}

export const RoleRequest: React.FC<RoleRequestProps> = ({
  roleRequest,
  setSelectRoleRequestId,
  isRowSelected
}) => {
  const {t, i18n} = useTranslation()

  const styles = useStyles()

  const {
    createdOn,
    expiryDate,
    usageCount,
    usageLimit,
    roles,
    usedBy: usedByWithDeletedUsers,
    id
  } = roleRequest

  const usedBy = usedByWithDeletedUsers.map((item) => {
    if (item.userInformation) return item

    return {
      ...item,
      userInformation: {
        name: t('invitations.user deleted'),
        eMail: t('invitations.user email not available')
      }
    }
  })

  const linkStates = getRoleRequestStates(roleRequest)
  const hasNewUser = usedBy.some(checkIfUsedByIsNewUser)

  const invitationLinkUsage = useMemo(() => {
    const progress = (usageCount / usageLimit) * 100
    const limit = usageLimit < 0 ? t('invitations.unlimited') : usageLimit

    return {
      progress,
      count: usageCount,
      limit
    }
  }, [t, usageCount, usageLimit])

  const role = roles[0]
  const {dataScope} = role
  const customerId = dataScope.customerIds?.length ? dataScope.customerIds[0] : undefined
  const payerId = dataScope.payerIds?.length ? dataScope.payerIds[0] : undefined

  const orgUnitConfigurationQueryInfo = useOrgUnitConfiguration(
    dataScope?.businessLine,
    dataScope?.countryId
  )

  const orgUnitConfiguration = orgUnitConfigurationQueryInfo.data?.find(
    ({orgUnitId}) => orgUnitId === dataScope.orgUnitId
  )

  const countryBusinessLineAndOrgUnit = !role
    ? ''
    : `${dataScope?.countryId}/${dataScope?.businessLine}/${
        orgUnitConfiguration?.orgUnitName ?? ''
      }`

  const customerDetailsQueryInfo = useCustomerById(customerId)
  const payerDetailsQueryInfo = usePayerById(payerId)

  const isLoading = customerDetailsQueryInfo.isLoading || payerDetailsQueryInfo.isLoading
  const name = customerDetailsQueryInfo.data?.customerName || payerDetailsQueryInfo.data?.payerName
  const accountNumber =
    customerDetailsQueryInfo.data?.customerNumber || payerDetailsQueryInfo.data?.payerNumber

  const countProjectAndSites = useMemo(() => {
    const projectIds = role?.dataScope?.projectIds ?? []
    const siteIds = role?.dataScope?.siteIds ?? []

    return `${projectIds?.length ? projectIds?.length : t('invitations.all')} ${t(
      'invitations.projects'
    )} ${siteIds?.length ? siteIds?.length : t('invitations.all')} ${t('invitations.sites')}`
  }, [t, role])

  const renderCustomerName = () => {
    const nowrap: CSSProperties = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px'
    }
    if (customerDetailsQueryInfo.error) {
      return (
        <Typography
          paragraph
          data-test-id={`${roleRequest.id}-customerName`}
          className={styles.title}
          style={{
            color: ERROR_ORANGE,
            ...nowrap
          }}
        >
          {`${t('invitations.customer not found')}.${customerId}`}
        </Typography>
      )
    }

    return (
      <Typography
        paragraph
        data-test-id={`${roleRequest.id}-customerName`}
        style={{color: 'black', ...nowrap}}
        className={styles.title}
      >
        {isLoading ? t('invitations.customer loading') : name}
      </Typography>
    )
  }

  const renderCustomerNumber = () => {
    if (customerDetailsQueryInfo.error) {
      return null
    }

    return (
      <Typography variant="subtitle2" color="secondary">
        {isLoading ? null : ` ${t('invitations.account number')} ${accountNumber}`}
      </Typography>
    )
  }

  return (
    <Box
      onClick={() => setSelectRoleRequestId(roleRequest.id)}
      style={{cursor: 'pointer'}}
      data-test-id={`${roleRequest.id}-roleRequest`}
    >
      <Grid
        container
        // spacing={2}
        className={classNames(styles.listElement, {[styles.selectedListElement]: isRowSelected})}
      >
        <Grid
          item
          xs={3}
          data-test-id={`${roleRequest.id}-dateBox`}
          style={{display: 'flex', alignItems: 'center'}}
        >
          <Box mr={1} style={{paddingTop: '2px'}}>
            <Brightness1Icon
              data-test-id={`${roleRequest.id}-newUser`}
              style={{
                fontSize: NEW_USER_BUBBLE_FONT_SIZE,
                color: hasNewUser ? '#53C069' : 'transparent'
              }}
            />
          </Box>

          {/* <Box>*/}
          {/*  <Typography paragraph className={styles.date}>*/}
          {/*    {dateFormatter(createdOn, i18n.language)}*/}
          {/*  </Typography>*/}
          {/*  <Typography paragraph className={styles.date}>*/}
          {/*    {dateFormatter(expiryDate, i18n.language)}*/}
          {/*  </Typography>*/}
          {/* </Box>*/}
        </Grid>
        {/* <Grid item xs={3} data-test-id={`${roleRequest.id}-mainBox`}>*/}
        {/*  <Typography variant="subtitle2" className={styles.titleAbove}>*/}
        {/*    {countryBusinessLineAndOrgUnit}*/}
        {/*  </Typography>*/}
        {/*  {renderCustomerName()}*/}
        {/*  {renderCustomerNumber()}*/}
        {/*  <Typography variant="subtitle1">{countProjectAndSites}</Typography>*/}
        {/* </Grid>*/}
        <Grid item xs={3}>
          <Box data-test-id={`${roleRequest.id}-labels`}>
            {linkStates.map((state, i) => (
              <RoleRequestStateTag state={state} key={i} />
            ))}
          </Box>
          <Box data-test-id={`${roleRequest.id}-info`}>
            <Typography variant="subtitle2" className={styles.info}>
              {t(`invitations.roleTypesSubtitles.${role.roleType}`, {
                defaultValue: ' '
              })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} data-test-id={`${roleRequest.id}-progress`}>
          <Typography
            style={{
              color: invitationLinkUsage.progress >= 80 ? ERROR_ORANGE : 'black'
            }}
            variant="subtitle1"
            data-test-id={`${roleRequest.id}-linkUsage`}
          >
            {invitationLinkUsage.count}/{invitationLinkUsage.limit} {t('invitations.used')}
          </Typography>
          <LinearProgress
            className={styles.borderLineProgess}
            variant="determinate"
            value={invitationLinkUsage.progress}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
