import {HCThemeType} from '@hconnect/uikit'
import {
  alpha,
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Popper,
  Typography,
  TextField
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SearchIcon from '@material-ui/icons/Search'
import {CircularProgress} from '@mui/material'
import classNames from 'classnames'
import clsx from 'clsx'
import React from 'react'

const useStyle = makeStyles((theme: HCThemeType) => ({
  error: {
    borderColor: 'red!important'
  },
  root: {
    minWidth: '220px',
    height: '100%',
    background: '#FBFBFC',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)',
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '& input': {
      fontSize: 16,
      color: alpha(theme.palette.secondary.dark, 0.96),
      fontWeight: 500,
      lineHeight: '19px',
      marginTop: 6
    }
  },
  disabledRoot: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  textfield: {
    margin: 0,
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(),
      left: theme.spacing(2.5),
      color: alpha(theme.palette.secondary.dark, 0.6),
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      transform: 'none'
    },
    '& .MuiInputBase-root': {
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(2.25),
      marginBottom: theme.spacing(1),
      fontSize: 16,
      alignItems: 'flex-end',
      '& .MuiInputBase-input': {
        padding: 0
      }
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        border: 'none'
      }
    }
  },
  icon: {
    display: 'inline-block',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  popper: {
    zIndex: 99,
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    overflowY: 'auto',
    maxHeight: '250px',
    '& > ul': {
      paddingTop: '0px'
    }
  }
}))

export interface Item {
  label: string
  value: string
}

interface LookupDropdown {
  showSubTitle?: boolean
  placeholder?: string
  label: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (value: string) => void
  'data-test-id': string
  error?: boolean
  items?: Item[]
  width?: number
  className?: string
  popperClassName?: string
  helperText?: string
  loading?: boolean
  textFieldClassName?: string
  disabled?: boolean
}

export const LookupDropdown: React.FC<LookupDropdown> = ({
  showSubTitle,
  placeholder,
  label,
  value,
  onChange,
  onSubmit,
  'data-test-id': dataTestId,
  error,
  items,
  width,
  className,
  popperClassName,
  helperText,
  loading,
  textFieldClassName,
  disabled
}) => {
  const classes = useStyle()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLInputElement | null>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent, item: Item) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)

    onSubmit(item.value)
  }

  const handleSubmit = () => {
    const value = anchorRef.current?.querySelector('input')?.getAttribute('value') as string
    onSubmit(value)
  }

  return (
    <Box style={{display: 'flex', flexDirection: 'column'}}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        data-test-id="lookup-dropdown-box"
        className={classNames(
          classes.root,
          {[classes.error]: error},
          {[classes.disabledRoot]: disabled},
          className
        )}
        width={width}
      >
        <TextField
          className={clsx(classes.textfield, textFieldClassName)}
          data-test-id="lookup-dropdown-field"
          disabled={disabled}
          label={label}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          ref={anchorRef}
          onKeyPress={(event: React.KeyboardEvent) => {
            if (event.key === 'Enter') {
              handleSubmit()
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            const {key} = event
            if ((key === 'Backspace' || key === 'Delete') && value.length === 1) {
              onSubmit('')
            }
          }}
          margin="normal"
        />
        <div>
          {loading ? (
            <CircularProgress size={24} style={{marginRight: '12px'}} />
          ) : items?.length ? (
            <IconButton onClick={handleToggle} className={classes.icon}>
              <KeyboardArrowDown data-test-id={dataTestId} />
            </IconButton>
          ) : (
            <IconButton
              className={classes.icon}
              onClick={handleSubmit}
              data-test-id="lookup-simple-button"
            >
              <SearchIcon />
            </IconButton>
          )}
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          className={classNames(classes.popper, popperClassName)}
          style={{width}}
        >
          <MenuList>
            {items?.map((item, index) => {
              return (
                <MenuItem
                  key={`lookup-dropdown-item-${index}`}
                  onClick={(event) => handleClose(event, item)}
                  selected={value === item.value}
                  data-test-id={`lookup-dropdown-item-${index}`}
                  style={{flexDirection: 'column', alignItems: 'flex-start', whiteSpace: 'normal'}}
                >
                  <Typography>{item.label}</Typography>

                  {showSubTitle && <Typography>{item.value}</Typography>}
                </MenuItem>
              )
            })}
          </MenuList>
        </Popper>
      </Box>
      {helperText && <Typography style={{color: 'red'}}>{helperText}</Typography>}
    </Box>
  )
}
